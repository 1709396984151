import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import RelatedBook from '../../Related/RelatedBook.vue'
import RelatedElement from '../../Related/RelatedElement.vue'
import RelatedEntry from '../../Related/RelatedEntry.vue'
import RelatedImage from '../../Related/RelatedImage.vue'
import RelatedAudio from '../../Related/RelatedAudio.vue'
import RelatedVideo from '../../Related/RelatedVideo.vue'
import CheckInService from '../../../api/CheckInService.js'
import LoginPop from '../../Login/LoginPop.vue'
import VIPLayer from '../../../components/VIPLayer.vue'

export default {
	name: 'BookDetail',
	data() {
		return {
			bookInfo: '',
			collectStatus: 0,
			showLogin: false,
		}
	},
	methods: {
		GetBookDetail() {
			//获取图书详情
			var that = this
			CheckInService.GetBookModel(
				that.$route.query.id,
				that.$UserID
			).then((res) => {
				console.log(res)
				if (res.Result.BookModel.ReleaseState == 1) {
					that.bookInfo = res.Result
					that.collectStatus = res.Result.ComStatus
				} else {
					that.$alert('该资源未发布', '提示', {
						confirmButtonText: '确定',
						callback: (action) => {
							if (action == 'confirm') {
								window.close()
							}
							window.close()
						},
					})
				}
			})
		},
		InsertCollection() {
			//添加收藏
			var that = this
			if (that.$UserID) {
				CheckInService.InsertUserColletion(
					that.$route.query.id,
					that.bookInfo.BookModel.res_name,
					that.$UserID,
					2
				).then((res) => {
					if (res.Tag == 1) {
						that.collectStatus = 1
						that.$alert('收藏成功', '提示', {
							confirmButtonText: '确定',
						})
					} else {
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定',
						})
					}
				})
			} else {
				that.showLogin = false
				that.showLogin = true
			}
		},
		CancelCollection() {
			var that = this
			if (that.$UserID) {
				CheckInService.UpdateUserColletion(
					that.$UserID,
					that.$route.query.id
				).then((res) => {
					if (res.Tag == 1) {
						that.collectStatus = 0
						that.$alert('取消收藏成功', '提示', {
							confirmButtonText: '确定',
						})
					} else {
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定',
						})
					}
				})
			} else {
				that.showLogin = false
				that.showLogin = true
			}
		},
		toReading() {
			console.log('213')

			//阅读
			var that = this

			// window.open(that.$WebUrl + 'ReadEpub?id=' + that.$route.query.id)

			if (that.$UserID) {
				if (that.$ServiceType == 1) {
					//vip
					if (that.bookInfo.BookModel.UnEPath) {
						window.open(
							that.$WebUrl + 'ReadEpub?id=' + that.$route.query.id
						)
					} else {
						window.open(
							that.$WebUrl + 'ReadPdf?id=' + that.$route.query.id
						)
					}
				} else {
					that.$alert('请联系平台管理员开通VIP服务', '提示', {
						confirmButtonText: '确定',
					})
				}
			} else {
				that.showLogin = false
				that.showLogin = true
			}
		},
		toElementDetail(value) {
			//词条详情
			if (value.type == 1 && value.ReleaseStatus != 0) {
				window.open(this.$WebUrl + 'ElementDetail?id=' + value.id)
			}
		},
		toLogin() {
			this.showLogin = false
			this.showLogin = true
		},
	},
	mounted() {
		this.GetBookDetail()
	},
	components: {
		Header: Header,
		Footer: Footer,
		RelatedBook: RelatedBook,
		RelatedElement: RelatedElement,
		RelatedEntry: RelatedEntry,
		RelatedImage: RelatedImage,
		RelatedAudio: RelatedAudio,
		RelatedVideo: RelatedVideo,
		LoginPop: LoginPop,
		VIPLayer: VIPLayer,
	},
}
